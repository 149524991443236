// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";

function rpmAgentPage(id) {
  return "https://secure.rpmtelco.com/rpm/Page/AgentRep.aspx?Item=" + ID.toString(id);
}

var about = "/about-us";

var contact = "/contact";

var contactThankYou = "contact_us/thank_you";

var controlPlaneSignUp = "https://signup.controlplane.com/";

var privacyPolicy = "/privacy-policy";

var sitemap = "/sitemap";

var termsOfUse = "/terms-of-use";

var thanks = "/thanks";

var thanksProvider = "/thanks-provider";

export {
  about ,
  contact ,
  contactThankYou ,
  controlPlaneSignUp ,
  privacyPolicy ,
  rpmAgentPage ,
  sitemap ,
  termsOfUse ,
  thanks ,
  thanksProvider ,
}
/* ID Not a pure module */
