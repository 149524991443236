// extracted by mini-css-extract-plugin
export var blue = "ServiceSection__blue__pfZGP";
export var column = "ServiceSection__column__uPFFw";
export var contentColumn = "ServiceSection__contentColumn__PRwUf";
export var contentRow = "ServiceSection__contentRow__NcgcV";
export var gray = "ServiceSection__gray__DR7k1";
export var row = "ServiceSection__row__w7m6c";
export var serviceSection = "ServiceSection__serviceSection__DjPBp";
export var subtitle = "ServiceSection__subtitle__tIe6P";
export var title = "ServiceSection__title__rjm_W";
export var white = "ServiceSection__white__q1Wiu";